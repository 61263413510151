import React, { useEffect, useState } from "react";
import { Card, Button, Row, Container, Modal } from "react-bootstrap";
import axios from "axios";
import PreviewPPozdrav from "./PreviewPPozdrav";
import PreviewMdPP from "./PreviewMdPP";
import PreviewSmPP from "./PreviewSmPP";
import PreviewSmUmrlica from "./PreviewSmUmrlica";
import PreviewLgUmrlica from "./PreviewLgUmrlica";
import PreviewMdUmrlica from "./PreviewMdUmrlica";
import PreviewSmPomen from "./PreviewSmPomen";
import PreviewMdPomen from "./PreviewMdPomen";
import PreviewLgPomen from "./PreviewLgPomen";
import PreviewQrPP from "./PreviewQrPP";
import PreviewQrPomen from "./PreviewQrPomen";
import PreviewQrUmrlica from "./PreviewQrUmrlica";
import { API_URL, UMRLICE_LARAVEL_API_TOKEN } from "../config";

let prices = {};
const PredajCitulju = ({ isLoggedIn, multiple, setMultiple }) => {
  const [preview, setPreview] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [href, setHref] = useState(false);
  const [umrliceData, setUmrliceData] = useState([]);
  const [ppData, setPPData] = useState([]);
  const [pomenData, setPomenData] = useState([]);

  useEffect(() => {
    localStorage.setItem("multipleImages", false);
    getPrices();
  }, []);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleNoImagePost = (type, id) => {
    localStorage.setItem("multipleImages", true);
    if (type === "pomen") {
      setPomenData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.id === id) {
            // Set multiple to true for the selected item
            item.multiple = false;
            item.no_image = true;
            item.clicked = true;
            item.show_message = false;
            // Increase the price by 10
          }
          return item;
        });
        return updatedData;
      });
    } else if (type === "poslednji_pozdrav") {
      setPPData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.id === id) {
            // Set multiple to true for the selected item
            item.multiple = false;
            item.no_image = true;
            item.clicked = true;
            item.show_message = false;
            // Increase the price by 10
          }
          return item;
        });
        return updatedData;
      });
    }
  };

  const handleButtonClick = async (path, cardData) => {
    if (isLoggedIn === false) {
      handleShow();
      return;
    }
    if (!cardData.clicked && cardData.size !== "qr") {
      setUmrliceData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.id === cardData.id) {
            // Set multiple to true for the selected item
            item.show_message = true;
            // Increase the price by 10
          }

          return item;
        });
        return updatedData;
      });
      setPPData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.id === cardData.id) {
            item.show_message = true;
          }

          return item;
        });
        return updatedData;
      });
      setPomenData((prevData) => {
        const updatedData = prevData.map((item) => {
          if (item.id === cardData.id) {
            // Set multiple to true for the selected item
            item.show_message = true;
            // Increase the price by 10
          }

          return item;
        });
        return updatedData;
      });
    } else if (isLoggedIn === true) {
      setHref(path);
      let url = "";
      if (cardData.no_image === true) {
        url = path + `?multiple=no_image`;
      } else {
        url = path + `?multiple=${cardData.multiple}`;
      }
      axios
        .get(
          `https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/get?user_id=${localStorage.getItem(
            "userId"
          )}`
        )
        .then(async (res) => {
          if (res.data.data?.id) {
            const response = await fetch(
              `${API_URL}/api/posts/${res.data.data.id}/`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "accessToken"
                  )}`,
                },
              }
            );

            if (response.ok) {
              console.log("Post declined successfully");
            } else {
              console.log("Error declining post:", response.status);
            }
          }
        });
      const updateCart = await axios.get(
        `https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/update?user_id=${localStorage.getItem(
          "userId"
        )}&price=${
          cardData.multiple ? cardData.price_multiple : cardData.price_single
        }`
      );

      // const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      // if (newWindow) newWindow.opener = null;
      window.location.href = url;
    }
  };
  async function getPrices() {
    const apiUrl = `${API_URL}/api/prices`;

    try {
      const response = await axios.get(apiUrl);
      response.data.forEach(function (val) {
        return (
          (prices[val.type + "_" + val.size + "_multiple"] =
            val.price_multiple),
          (prices[val.type + "_" + val.size + "_single"] = val.price_single)
        );
      });

      setUmrliceData([
        {
          id: 0,
          title: "Najmanja",
          description: [
            "Do 80 karaktera zavisno od obilježja / prikaz čitulje je u veličini polovine ekrana 1/2",
          ],
          path_to_open: "/umrlica-qr-form",
          multiple: false,
          function_to_show: () => setPreview("qr-umrlica"),
          input_field_name: "najmanja-umrlica",
          radio: false,
          price_single: prices.umrlica_4_single,
          price_multiple: prices.umrlica_4_multiple,
          show_message: false,
          size: "qr",
        },
        {
          id: 1,
          title: "Mali format umrlice",
          description: [
            "Do 400 karaktera zavisno od obilježja / prikaz čitulje je u veličini polovine ekrana 1/2",
          ],
          path_to_open: "/umrlica-sm-form",
          multiple: false,
          function_to_show: () => setPreview("sm-umrlica"),
          radio: true,
          input_field_name: "mala-umrlica",
          price_single: prices.umrlica_1_single.toFixed(2),
          price_multiple: prices.umrlica_1_multiple.toFixed(2),
          show_message: false,
        },
        {
          id: 2,
          title: "Srednji format umrlice",
          description: [
            "Do 880 karaktera zavisno od obilježja / prikaz čitulje je u veličina cijelog ekrana 1/2",
          ],
          path_to_open: "/umrlica-md-form",
          function_to_show: () => setPreview("md-umrlica"),
          radio: true,
          input_field_name: "srednja-umrlica",
          multiple: false,
          price_single: prices.umrlica_2_single.toFixed(2),
          price_multiple: prices.umrlica_2_multiple.toFixed(2),
          show_message: false,
        },
        {
          id: 3,
          title: "Veliki format umrlice",
          description: [
            "Do 1240 karaktera zavisno od obilježja / prikaz čitulje je u veličina cijelog ekrana 1/1",
          ],
          path_to_open: "/umrlica-lg-form",
          multiple: false,
          function_to_show: () => setPreview("lg-umrlica"),
          radio: true,
          input_field_name: "velika-umrlica",
          price_single: prices.umrlica_3_single.toFixed(2),
          price_multiple: prices.umrlica_3_multiple.toFixed(2),
          show_message: false,
        },
      ]);

      setPPData([
        {
          id: 4,
          title: "Najmanji format posljednjeg pozdrava",
          description: [
            "do 80 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/2",
          ],
          path_to_open: "/pp-qr-form",
          multiple: false,
          function_to_show: () => setPreview("qr-pp"),
          input_field_name: "najmanji-pp",
          radio: false,
          price_single: prices.poslednji_pozdrav_4_single.toFixed(2),
          price_multiple: prices.poslednji_pozdrav_4_multiple.toFixed(2),
          show_message: false,
          size: "qr",
        },
        {
          id: 5,
          title: "Mali format posljednjeg pozdrava",
          description: [
            "do 430 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/2",
          ],
          path_to_open: "/pp-sm-form",
          multiple: false,
          function_to_show: () => setPreview("sm-pp"),
          radio: true,
          input_field_name: "malI-PP",
          price_single: prices.poslednji_pozdrav_1_single.toFixed(2),
          price_multiple: prices.poslednji_pozdrav_1_multiple.toFixed(2),
          show_message: false,
        },
        {
          id: 6,
          title: "Srednji format posljednjeg pozdrava",
          description: [
            "do 720 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/2",
          ],
          path_to_open: "/pp-md-form",
          function_to_show: () => setPreview("md-pp"),
          radio: true,
          input_field_name: "md-pp",
          multiple: false,
          price_single: prices.poslednji_pozdrav_2_single.toFixed(2),
          price_multiple: prices.poslednji_pozdrav_2_multiple.toFixed(2),
          show_message: false,
        },
        {
          id: 7,
          title: "Veliki format posljednjeg pozdrava",
          description: [
            "do 1010 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/1",
          ],
          path_to_open: "/pp-lg-form",
          multiple: false,
          function_to_show: () => setPreview("lg-pp"),
          radio: true,
          input_field_name: "veliki-pp",
          price_single: prices.poslednji_pozdrav_3_single.toFixed(2),
          price_multiple: prices.poslednji_pozdrav_3_multiple.toFixed(2),
          show_message: false,
        },
      ]);
      setPomenData([
        {
          id: 8,
          title: "Najmanji format pomena",
          description: [
            "do 130 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/2",
          ],
          path_to_open: "/pomen-qr-form",
          multiple: false,
          function_to_show: () => setPreview("qr-pomen"),
          input_field_name: "najmanji-pomen",
          radio: false,
          price_single: prices.pomen_4_single.toFixed(2),
          price_multiple: prices.pomen_4_multiple.toFixed(2),
          show_message: false,
          size: "qr",
        },
        {
          id: 9,
          title: "Mali format pomena",
          description: [
            "do 150 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/2",
          ],
          path_to_open: "/pomen-sm-form",
          multiple: false,
          function_to_show: () => setPreview("sm-pomen"),
          radio: true,
          input_field_name: "mali-pomen",
          price_single: prices.pomen_1_single.toFixed(2),
          price_multiple: prices.pomen_1_multiple.toFixed(2),
          show_message: false,
        },
        {
          id: 6,
          title: "Srednji format pomena",
          description: [
            "do 720 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/2",
          ],
          path_to_open: "/pomen-md-form",
          function_to_show: () => setPreview("md-pomen"),
          radio: true,
          input_field_name: "md-pomen",
          multiple: false,
          price_single: prices.pomen_2_single.toFixed(2),
          price_multiple: prices.pomen_2_multiple.toFixed(2),
          show_message: false,
        },
        {
          id: 7,
          title: "Veliki format pomena",
          description: [
            "do 1010 karaktera zavisno od obilježja / prikaz čitulje je u veličini četvrtine ekrana 1/1",
          ],
          path_to_open: "/pomen-lg-form",
          multiple: false,
          function_to_show: () => setPreview("lg-pomen"),
          radio: true,
          input_field_name: "veliki-pp",
          price_single: prices.pomen_3_single.toFixed(2),
          price_multiple: prices.pomen_3_multiple.toFixed(2),
          show_message: false,
        },
      ]);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    pomenData.map((card) => (card.clicked = false));
    umrliceData.map((card) => (card.clicked = false));
    ppData.map((card) => (card.clicked = false));
  }, []);

  const toggleUmrliceMultiple = (id) => {
    localStorage.setItem("multipleImages", true);
    setUmrliceData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id === id) {
          // Set multiple to true for the selected item
          item.multiple = true;
          item.clicked = true;
          item.show_message = false;
          item.no_image = false;
          // Increase the price by 10
        }

        return item;
      });
      return updatedData;
    });
  };

  const togglePPMultiple = (id) => {
    setPPData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id === id) {
          // Set multiple to true for the selected item
          item.multiple = true;
          item.clicked = true;
          item.show_message = false;
          item.no_image = false;
        }

        return item;
      });
      return updatedData;
    });
  };
  const togglePomenMultiple = (id) => {
    setPomenData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id === id) {
          // Set multiple to true for the selected item
          item.multiple = true;
          item.clicked = true;
          item.show_message = false;
          item.no_image = false;
          // Increase the price by 10
        }

        return item;
      });
      return updatedData;
    });
  };

  const togglePPSingle = (id) => {
    localStorage.setItem("multipleImages", false);
    setPPData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id === id) {
          // Set multiple to true for the selected item
          item.multiple = false;
          item.clicked = true;
          item.show_message = false;
          item.no_image = false;
          // Increase the price by 10
        }

        return item;
      });

      return updatedData;
    });
  };
  const togglePomenSingle = (id) => {
    localStorage.setItem("multipleImages", false);
    setPomenData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id === id) {
          // Set multiple to true for the selected item
          item.multiple = false;
          item.clicked = true;
          item.show_message = false;
          item.no_image = false;
          // Increase the price by 10
        }

        return item;
      });

      return updatedData;
    });
  };
  const toggleUmrlicaSingle = (id) => {
    localStorage.setItem("multipleImages", false);
    setUmrliceData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id === id) {
          // Set multiple to true for the selected item
          item.multiple = false;
          item.clicked = true;
          item.show_message = false;
          item.no_image = false;
          // Increase the price by 10
        }

        return item;
      });

      return updatedData;
    });
  };
  return (
    <div style={{ paddingTop: "100px" }}>
      <h3
        style={{ textAlign: "center", paddingTop: "20px" }}
        className="predaj-citulju-page"
      >
        Umrlice
      </h3>

      <Container style={{ paddingTop: "0px" }}>
        <Row className="justify-content-center">
          {umrliceData.map((cardData, index) => (
            <Card
              sm={12}
              style={{
                maxWidth: "300px",
                marginLeft: "1%",
                marginTop: "2%",
                border: "none",
              }}
              className="umrlice-card"
              key={index}
            >
              {" "}
              <Card.Body>
                <Card.Title>{cardData.title}</Card.Title>
                <div>
                  <p style={{ textAlign: "center" }}>{cardData.description}</p>

                  <div>
                    <div className="price-container">
                      <span style={{ fontSize: "20px" }} className="price">
                        {cardData.multiple === false
                          ? cardData.price_single + " €"
                          : cardData.price_multiple + " €"}
                      </span>
                    </div>
                  </div>

                  {cardData.radio === false && <div></div>}
                </div>
                {cardData.radio === true && (
                  <div style={{ paddingLeft: "0" }} className="form-check">
                    <input
                      readOnly
                      onClick={() => toggleUmrlicaSingle(cardData.id)} // Call the toggle function to increase the price
                      className="form-check-input"
                      type="radio"
                      name={cardData.input_field_name}
                      id={`flexRadioDefault1_${cardData.input_field_name}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexRadioDefault1_${cardData.input_field_name}`}
                    >
                      Jedna fotografija
                    </label>
                  </div>
                )}
                {cardData.radio === true && (
                  <div style={{ paddingLeft: "0" }} className="form-check">
                    <input
                      readOnly
                      className="form-check-input"
                      type="radio"
                      name={cardData.input_field_name}
                      onClick={() => toggleUmrliceMultiple(cardData.id)} // Call the toggle function to increase the price
                      id={`flexRadioDefault2_${cardData.input_field_name}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexRadioDefault2_${cardData.input_field_name}`}
                    >
                      Više fotografija
                    </label>{" "}
                  </div>
                )}

                {cardData.radio === false && (
                  <div>
                    <br></br>
                    <br></br>
                  </div>
                )}
                {cardData.show_message && (
                  <span style={{ color: "red" }}>
                    Morate izabrati broj fotografija
                  </span>
                )}
                <br></br>
                <br></br>
                <br></br>

                <div className="button-container">
                  <Button
                    className="pogledaj-btn"
                    onClick={cardData.function_to_show}
                  >
                    Pogledaj{" "}
                    <img
                      style={{ width: "25%", marginLeft: "5px" }}
                      src={require("../assets/show-regular-24.png")}
                      alt="xxx"
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      handleButtonClick(cardData.path_to_open, cardData)
                    }
                    className="predaj-btn"
                  >
                    Predaj{" "}
                    <img
                      style={{ width: "25%", marginLeft: "5px" }}
                      src={require("../assets/edit-alt-solid-24.png")}
                      alt="xxx"
                    />
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </Row>

        <h3
          style={{ textAlign: "center", paddingTop: "20px" }}
          className="predaj-citulju-page"
        >
          Posljednji pozdravi
        </h3>

        <Row className="justify-content-center">
          {ppData.map((cardData, index) => (
            <Card
              sm={12}
              style={{
                maxWidth: "300px",
                marginLeft: "1%",
                marginTop: "2%",
                border: "none",
              }}
              className="umrlice-card"
              key={index}
            >
              {" "}
              <Card.Body>
                <Card.Title>{cardData.title}</Card.Title>
                <div>
                  <p style={{ textAlign: "center" }}>{cardData.description}</p>

                  <div>
                    <div className="price-container">
                      <span style={{ fontSize: "20px" }} className="price">
                        {cardData.multiple === false
                          ? cardData.price_single + " €"
                          : cardData.price_multiple + " €"}
                      </span>
                    </div>
                  </div>

                  {cardData.radio === false && (
                    <div>
                      <br></br>
                      <br></br>
                    </div>
                  )}
                </div>{" "}
                {cardData.radio === true &&
                  cardData.path_to_open === "/pp-sm-form" && (
                    <div style={{ paddingLeft: "0" }} className="form-check">
                      <input
                        readOnly
                        className="form-check-input"
                        type="radio"
                        name={cardData.input_field_name}
                        onClick={() =>
                          handleNoImagePost("poslednji_pozdrav", cardData.id)
                        }
                        id={`flexRadioDefault3_${cardData.input_field_name}`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexRadioDefault3_${cardData.input_field_name}`}
                      >
                        Bez fotografije
                      </label>
                    </div>
                  )}
                {cardData.radio === true && (
                  <div style={{ paddingLeft: "0" }} className="form-check">
                    <input
                      readOnly
                      onClick={() => togglePPSingle(cardData.id)} // Call the toggle function to increase the price
                      className="form-check-input"
                      type="radio"
                      name={cardData.input_field_name}
                      id={`flexRadioDefault1_${cardData.input_field_name}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexRadioDefault1_${cardData.input_field_name}`}
                    >
                      Jedna fotografija
                    </label>
                  </div>
                )}
                {cardData.radio === true && (
                  <div style={{ paddingLeft: "0" }} className="form-check">
                    <input
                      readOnly
                      className="form-check-input"
                      type="radio"
                      name={cardData.input_field_name}
                      onClick={() => togglePPMultiple(cardData.id)} // Call the toggle function to increase the price
                      id={`flexRadioDefault2_${cardData.input_field_name}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexRadioDefault2_${cardData.input_field_name}`}
                    >
                      Više fotografija
                    </label>
                  </div>
                )}
                {cardData.show_message && (
                  <span style={{ color: "red" }}>
                    Morate izabrati broj fotografija
                  </span>
                )}
                {cardData.radio === false && <div></div>}
                <br></br>
                <br></br>
                <br></br>
                <div className="button-container">
                  <Button
                    className="pogledaj-btn"
                    onClick={cardData.function_to_show}
                  >
                    Pogledaj{" "}
                    <img
                      style={{ width: "25%", marginLeft: "5px" }}
                      src={require("../assets/show-regular-24.png")}
                      alt="xxx"
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      handleButtonClick(cardData.path_to_open, cardData)
                    }
                    className="predaj-btn"
                  >
                    Predaj{" "}
                    <img
                      style={{ width: "25%", marginLeft: "5px" }}
                      src={require("../assets/edit-alt-solid-24.png")}
                      alt="xxx"
                    />
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </Row>

        <h3
          style={{ textAlign: "center", paddingTop: "20px" }}
          className="predaj-citulju-page"
        >
          Pomeni
        </h3>

        <Row className="justify-content-center">
          {pomenData.map((cardData, index) => (
            <Card
              sm={12}
              style={{
                maxWidth: "300px",
                marginLeft: "1%",
                marginTop: "2%",
                border: "none",
              }}
              className="umrlice-card"
              key={index}
            >
              {" "}
              <Card.Body>
                <Card.Title>{cardData.title}</Card.Title>
                <div>
                  <p style={{ textAlign: "center" }}>{cardData.description}</p>

                  <div>
                    <div className="price-container">
                      <span style={{ fontSize: "20px" }} className="price">
                        {cardData.multiple === false
                          ? cardData.price_single + " €"
                          : cardData.price_multiple + " €"}
                      </span>
                    </div>
                  </div>

                  {cardData.radio === false && <div></div>}
                </div>{" "}
                {cardData.radio === true &&
                  cardData.path_to_open === "/pomen-sm-form" && (
                    <div style={{ paddingLeft: "0" }} className="form-check">
                      <input
                        readOnly
                        className="form-check-input"
                        type="radio"
                        name={cardData.input_field_name}
                        onClick={() => handleNoImagePost("pomen", cardData.id)}
                        id={`flexRadioDefault3_${cardData.input_field_name}`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`flexRadioDefault3_${cardData.input_field_name}`}
                      >
                        Bez fotografije
                      </label>
                    </div>
                  )}
                {cardData.radio === true && (
                  <div style={{ paddingLeft: "0" }} className="form-check">
                    <input
                      readOnly
                      onClick={() => togglePomenSingle(cardData.id)} // Call the toggle function to increase the price
                      className="form-check-input"
                      type="radio"
                      name={cardData.input_field_name}
                      id={`flexRadioDefault1_${cardData.input_field_name}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexRadioDefault1_${cardData.input_field_name}`}
                    >
                      Jedna fotografija
                    </label>
                  </div>
                )}
                {cardData.radio === true && (
                  <div style={{ paddingLeft: "0" }} className="form-check">
                    <input
                      readOnly
                      className="form-check-input"
                      type="radio"
                      name={cardData.input_field_name}
                      onClick={() => togglePomenMultiple(cardData.id)} // Call the toggle function to increase the price
                      id={`flexRadioDefault2_${cardData.input_field_name}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexRadioDefault2_${cardData.input_field_name}`}
                    >
                      Više fotografija
                    </label>
                  </div>
                )}{" "}
                {cardData.show_message && (
                  <span style={{ color: "red" }}>
                    Morate izabrati broj fotografija
                  </span>
                )}
                {cardData.radio === false && (
                  <div>
                    <br></br>
                    <br></br>
                  </div>
                )}
                <br></br>
                <br></br>
                <br></br>
                <div className="button-container">
                  <Button
                    className="pogledaj-btn"
                    onClick={cardData.function_to_show}
                  >
                    Pogledaj{" "}
                    <img
                      style={{ width: "25%", marginLeft: "5px" }}
                      src={require("../assets/show-regular-24.png")}
                      alt="xxx"
                    />
                  </Button>
                  <Button
                    onClick={() =>
                      handleButtonClick(cardData.path_to_open, cardData)
                    }
                    className="predaj-btn"
                  >
                    Predaj{" "}
                    <img
                      style={{ width: "25%", marginLeft: "5px" }}
                      src={require("../assets/edit-alt-solid-24.png")}
                      alt="xxx"
                    />
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </Row>
      </Container>
      {preview === "qr-umrlica" && (
        <Modal
          show={preview === "qr-umrlica"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewQrUmrlica />
            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")}
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
      {preview === "lg-pp" && (
        <Modal
          show={preview === "lg-pp"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="xl"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewPPozdrav multiple={multiple} />
            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
      {preview === "md-pp" && (
        <Modal
          show={preview === "md-pp"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewMdPP multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")}
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
      {preview === "sm-pp" && (
        <Modal
          show={preview === "sm-pp"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewSmPP multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
      {preview === "qr-pp" && (
        <Modal
          show={preview === "qr-pp"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewQrPP />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
      {preview === "sm-umrlica" && (
        <Modal
          show={preview === "sm-umrlica"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewSmUmrlica multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}

      {preview === "md-umrlica" && (
        <Modal
          show={preview === "md-umrlica"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewMdUmrlica multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}

      {preview === "lg-umrlica" && (
        <Modal
          show={preview === "lg-umrlica"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="xl"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewLgUmrlica multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
      {preview === "qr-pomen" && (
        <Modal
          show={preview === "qr-pomen"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewQrPomen />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
      {preview === "sm-pomen" && (
        <Modal
          show={preview === "sm-pomen"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewSmPomen multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}

      {preview === "md-pomen" && (
        <Modal
          show={preview === "md-pomen"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="md"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewMdPomen multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")} // Call handleClosePreviewModal on button click
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>Nijeste ulogovani</Modal.Header>
        <Modal.Body>
          Molimo vas da se registrujete ili ulogujete kako bi predali umrlicu
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <Button
            href="/login"
            variant="secondary"
            onClick={handleClose}
            style={{ marginRight: "10px" }}
          >
            Uloguj se
          </Button>
          <Button href="/register" variant="primary" onClick={handleClose}>
            Registruj se
          </Button>
        </Modal.Footer>
      </Modal>

      {preview === "lg-pomen" && (
        <Modal
          show={preview === "lg-pomen"}
          onHide={() => setPreview("")}
          contentlabel="Post Modal"
          size="xl"
          style={{ padding: "0px" }}
        >
          <Container className="px-3">
            <PreviewLgPomen multiple={multiple} />

            <Button
              variant="secondary"
              className="mt-4 float-right"
              onClick={() => setPreview("")}
            >
              Zatvori
            </Button>
          </Container>
        </Modal>
      )}
    </div>
  );
};

export default PredajCitulju;
